// Utils
import { get } from 'lodash';

export default {
    data() {
        return {
            MAX_ITEMS_PER_PAGE: 25,
            fullCriteriaItems: undefined,
            fullItems: [],
            hasFullLocalData: true,
            items: [],
            page: 1,
            sort: 'ASC',
            sortBy: null,
            sortField: null,
        };
    },

    methods: {
        /**
         * Applies the sort criteria locally if a sort field is specified.
         * If `this.sortField` is defined, it calls the `localSort` method to sort the data.
         */
        applySortCriteriaLocally() {
            if (this.sortField) {
                this.localSort();
            }
        },

        /**
         * Does a local sort (used when all elements are here)
        */
        localSort() {
            const baseArray = this.fullCriteriaItems ?? this.fullItems;
            baseArray.forEach(item => {
                let sortCacheValue;
                const value = get(item, this.sortField);
                if (value || value === 0) {
                    sortCacheValue = value;
                } else {
                    const refsItem = this.$refs[`${this.sortBy}_${item._id}`]?.[0];
                    sortCacheValue = refsItem?.innerText || '';
                }
                item.__sort_cache = sortCacheValue;
            });
            this.$utils.collections.sortByPropOfAnyType(baseArray, '__sort_cache', this.sort);
            this.items = baseArray.slice((this.page - 1) * this.MAX_ITEMS_PER_PAGE, this.page * this.MAX_ITEMS_PER_PAGE);
        },

        /**
         * Sets the sortable field and reload the collection
         *
         * @param {GenericListColumnConfig} cellConfig the configuration for the cell
         * @param {string} name the name of the cell
         */
        setSortable(cellConfig, name) {
            if (!cellConfig.sortable) {
                return;
            }

            const { sort, sortField } = this.$utils.tables.setSortable(cellConfig, this.sort, this.sortField);
            this.sort = sort;
            this.sortField = sortField;
            this.sortBy = name;

            if (this.hasFullLocalData) {
                this.localSort();
            } else {
                this.loadData();
            }
        },
    }
};
