import Hls, {
    ErrorData,
    LevelLoadedData,
    Level,
    MediaAttachedData,
    FragLoadEmergencyAbortedData,
    HlsConfig,
    LevelSwitchingData,
    FragLoadingData,
    LevelUpdatedData
} from 'hls.js';
import { cloneDeep, get, isEmpty, isFunction, isNumber } from 'lodash';
import EventBus, { Event, Unsubscriber } from '../event-bus';
import PlayerStateRepository, { PlayerState } from '../player-state';
import { sourceType, MediaType } from '../video-source';
import createHandlers, { HlsErrorHandler, CONTEXT } from './error-handlers';
import clientFacingHlsProvider from './utils/client-facing-hls';
import SmvpErrorHandler from './error-handlers/smvp-error-handler';
import hlsConfig from './hls.config';
import { getCapLevelController, hlsLevelToLevelDetails, resolveMaxLevel } from '@/core/hls/utils/levels';
import { isIos18OrHigherDevice, isLivePlaylist } from '@/core/hls/utils/utils';
import { HiveConfig, isHiveConfigValid, setupHive } from '@/core/hls/plugins/hive';

const supportsHls = (sourceUrl: string): boolean => !!sourceUrl && sourceType(sourceUrl) !== MediaType.mp4;
const mediaPlaying = (hls: Hls): boolean =>
    !!hls.media?.currentTime &&
    hls.media?.currentTime > 0 &&
    !hls.media?.paused &&
    !hls.media?.ended;

const RESOLUTION_LEVELS: Record<string, number> = {
    '1920x1080': 4,
    '1280x720': 3,
    '960x540': 2,
    '768x432': 1,
    '640x360': 0,
    'auto': -1
};

interface HlsProviderConfig {
    eventBus: EventBus;
    playerStateRepository: PlayerStateRepository;
    useCredentials: boolean;
    contentId: string;
    cmcdSessionId: string;
    hlsConfigOverwrites: Partial<HlsConfig>;
    hiveConfig: HiveConfig;
}

export default class HlsProvider {
    private hls?: Hls;

    private smvpErrorHandler?: SmvpErrorHandler;

    private readonly errorHandlers?: Record<string, HlsErrorHandler>;

    private isPlayingInterval = 0;

    private unsubscribers : Unsubscriber[] = [];

    private maxLevelFromConfig = -1;

    private maxLevelFromUser = -1;

    private startPosition = -1;

    private eventBus: EventBus;

    private playerStateRepository: PlayerStateRepository;

    private hiveConfig: HiveConfig;

    private useCredentials: boolean;

    constructor({
        eventBus,
        playerStateRepository,
        useCredentials,
        contentId,
        cmcdSessionId,
        hlsConfigOverwrites,
        hiveConfig
    }: HlsProviderConfig) {
        this.eventBus = eventBus;
        this.playerStateRepository = playerStateRepository;
        this.hiveConfig = hiveConfig;
        this.useCredentials = useCredentials;

        const isIos = isIos18OrHigherDevice();

        // TODO: Currently ios has an issue with ID3 tags, with the native hls player, so we need to use hls.js
        // Once their issue is fixed, we can use native hls player on ios again
        if (Hls.isSupported() || isIos) {
            console.log('[HlsProvider] hls supported, initializing...');

            let config: Partial<HlsConfig> = cloneDeep(hlsConfig);
            if (useCredentials) {
                console.log('[HlsProvider] use credentials');
                config.xhrSetup = (xhr: XMLHttpRequest) => {
                    xhr.withCredentials = useCredentials;
                };
            }

            this.updateOverrides(hlsConfigOverwrites);

            config.capLevelController = getCapLevelController(() => this.maxLevelFromConfig, () => this.maxLevelFromUser);

            if (!isEmpty(hlsConfigOverwrites)) {
                console.log('[HlsProvider] overwriting hls config with', hlsConfigOverwrites);

                config = {
                    ...config,
                    ...hlsConfigOverwrites
                };

                // not an hls config property so it should not be passed down to it
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                delete config.maxLevel;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                delete config.maxResolution;
            }

            if (contentId && cmcdSessionId && !!hlsConfigOverwrites?.cmcd) {
                config.cmcd = {
                    contentId: contentId,
                    sessionId: cmcdSessionId,
                    useHeaders: false,
                    ...hlsConfigOverwrites.cmcd
                };
                console.log('[HlsProvider] enable cmcd', config.cmcd);
            }

            this.hls = new Hls(config);
            this.hls.subtitleDisplay = false; // we manage subtitles ourselves
            this.smvpErrorHandler = new SmvpErrorHandler(this.hls, this.eventBus);
            this.errorHandlers = createHandlers(this.hls, eventBus, this.smvpErrorHandler);

            this.setupHlsListeners(this.hls);
        } else {
            // TODO: once ID3 is fixed on iOS native hls, add "likely an ios device, will use native hls" to the message
            console.log('[HlsProvider] hls unsupported');
        }

        const { unsubscriber, state } = this.playerStateRepository.onStateChange(
            this.onStateChange.bind(this)
        );

        this.onStateChange(state);

        this.unsubscribers.push(
            unsubscriber,
            this.eventBus.on(Event.selectLevel, (payload) =>
                this.onChangeLevel((payload as Record<string, number>)?.id)
            )
        );

        console.log('[HlsProvider] ready');
    }

    public async loadSource(video: HTMLVideoElement, src: string): Promise<string> {
        if (!this.hls || !supportsHls(src)) {
            throw ('Hls or source is not supported');
        }

        if (isHiveConfigValid(this.hiveConfig)) {
            return setupHive({
                hiveConfig: this.hiveConfig,
                src,
                useCredentials: this.useCredentials,
                hls: this.hls,
                video,
                loadSource: this.hlsLoadSource.bind(this)
            });
        }

        return this.hlsLoadSource(src);
    }

    private hlsLoadSource(src: string): Promise<string> {
        console.log('[HlsProvider] loading hls source', src);

        return new Promise((resolve, reject) => {
            if (!this.hls || !supportsHls(src)) {
                return reject('Hls or source is not supported');
            }

            const onParsed = (
                _: unknown,
                data: { levels: Array<unknown> }
            ) => {
                this.hls?.off(Hls.Events.MANIFEST_PARSED, onParsed);

                resolve(`[HlsProvider] Manifest loaded, found ${data.levels.length} quality level`);

                this.updateLevelsInStateRepo();
            };

            this.hls.on(Hls.Events.MANIFEST_PARSED, onParsed);
            this.hls.loadSource(src);
        });
    }

    public updateOverrides(hlsConfigOverwrites: Partial<HlsConfig>): void {
        this.maxLevelFromConfig = get(hlsConfigOverwrites, 'maxLevel', this.maxLevelFromConfig);
        this.startPosition = hlsConfigOverwrites?.startPosition ?? this.startPosition;

        const maxResolution: string = get(hlsConfigOverwrites, 'maxResolution');
        if (maxResolution) {
            this.maxLevelFromConfig = RESOLUTION_LEVELS[maxResolution] ?? RESOLUTION_LEVELS['auto'];
            console.log(`[HlsProvider] using config value for maxResolution: ${maxResolution}, mapping to level: ${this.maxLevelFromConfig}`);
        }

        this.updateLevelsInStateRepo();
    }

    private updateLevelsInStateRepo() {
        if (!this.hls) {
            return;
        }

        let levelsAvailable = (this.hls.levels as Level[]).map((level: Level) => hlsLevelToLevelDetails(level));

        if (this.maxLevelFromConfig >= 0) {
            levelsAvailable = levelsAvailable.slice(0, this.maxLevelFromConfig + 1);
        }

        console.debug('[HlsProvider] levels available update', levelsAvailable);
        this.playerStateRepository.setState({
            levelsAvailable
        });
    }

    public attachMedia(video: HTMLVideoElement): Promise<string> {
        console.log('[HlsProvider] attaching media...');

        return new Promise((resolve, reject) => {
            if (!this.hls) {
                return reject('Hls is not supported');
            }

            const onMediaAttached = () => {
                this.hls?.off(Hls.Events.MEDIA_ATTACHED, onMediaAttached);

                resolve('[HlsProvider] Media attached');
            };

            this.hls.on(Hls.Events.MEDIA_ATTACHED, onMediaAttached);
            this.hls.attachMedia(video);
        });
    }

    public dispose(): void {
        this.removeIsPlayingInterval();

        if (this.hls instanceof Hls) {
            this.hls.destroy();
            this.hls = undefined;
        }

        for (const unsubscriber of this.unsubscribers) {
            if (isFunction(unsubscriber)) {
                unsubscriber();
            }
        }
    }

    public isSrcSupported(src: string): boolean {
        return !!this.hls && supportsHls(src);
    }

    private setupHlsListeners(hls: Hls) {
        hls.on(Hls.Events.MEDIA_ATTACHED, this.onMediaAttached.bind(this, hls));
        hls.on(Hls.Events.MEDIA_DETACHED, this.onMediaDetached.bind(this, hls));
        hls.on(Hls.Events.DESTROYING, this.onDestroying.bind(this));
        hls.on(Hls.Events.ERROR, this.onHlsError.bind(this));
        hls.on(Hls.Events.LEVEL_LOADED, this.sendEventToClient.bind(this, hls));
        hls.on(Hls.Events.LEVEL_UPDATED, this.onLevelUpdated.bind(this));
        hls.on(Hls.Events.FRAG_LOAD_EMERGENCY_ABORTED, this.sendEventToClient.bind(this, hls));
        hls.on(Hls.Events.LEVEL_SWITCHING, this.onLevelSwitching.bind(this));
        hls.on(Hls.Events.FRAG_LOADING, this.onFragLoading.bind(this));
        hls.on(Hls.Events.FRAG_BUFFERED, this.onFragBuffered.bind(this));
    }

    private onPlaybackStuck(stuck: boolean) {
        if (this.stuck !== stuck) {
            this.playerStateRepository.setState({
                playback: {
                    isStuck: stuck
                }
            });
            this.smvpErrorHandler?.playbackLoadingErrorHandler(stuck);
        }
    }

    private onLevelSwitching(_: string, level: LevelSwitchingData) {
        this.playerStateRepository.setState({
            currentLevel: hlsLevelToLevelDetails(level)
        });
    }

    private onLevelUpdated(
        event: string,
        data: LevelUpdatedData
    ) {
        if (!this.hls) {
            return;
        }

        this.eventBus.emit(Event.customAction, {
            hls: clientFacingHlsProvider(this.hls),
            event,
            isLive: !!data.details?.live,
            averageTargetDuration: data.details?.averagetargetduration ?? 6
        }, CONTEXT);
    }

    private onFragLoading(_: string, data: FragLoadingData) {

        if (!this.startPosition || this.startPosition <= 0 || !this.hls?.media) {
            return;
        }

        const end = data?.frag?.end;

        // When the video ends, and user clicks play, the video restarts from the beginning, to prevent loading an extra useless fragment
        // we verify that the startPosition is inside the next downloaded fragment
        if (isNumber(end) && end < this.startPosition) {
            this.hls.media.currentTime = this.startPosition;
        }
    }

    private onFragBuffered() {
        if (!this.hls) {
            return;
        }
        const isLive = isLivePlaylist(this.hls);
        console.debug('Loaded a fragment', { isLive });
    }

    private setupIsPlayingInterval(hls: Hls): void {
        let lastHead = -Infinity;
        this.removeIsPlayingInterval();

        const handler = () => {
            if (!this.isPlaying) {
                // when the player is in pause state, then the playback is never stuck
                return this.onPlaybackStuck(false);
            }

            const head = hls.media?.currentTime ?? lastHead;
            const isPlaying = mediaPlaying(hls);
            const stuck = head <= lastHead && isPlaying;
            const isStuckOnStart = this.isPlaying && !isPlaying && head === -Infinity;

            const playbackStuck = stuck || isStuckOnStart;
            if (playbackStuck) {
                console.debug('[HlsProvider] isPlayingInterval', { stuck, isStuckOnStart, head, lastHead, isPlaying });
            }
            this.onPlaybackStuck(playbackStuck);

            lastHead = head;
        };

        this.isPlayingInterval = setInterval(handler, 200);
    }

    private removeIsPlayingInterval(): void {
        clearInterval(this.isPlayingInterval);
        this.onPlaybackStuck(false);
    }

    private onDestroying(event: string) {
        this.hls = undefined;
        this.removeIsPlayingInterval();
        this.eventBus.emit(Event.customAction, event, CONTEXT);
    }

    private onMediaAttached(hls: Hls, event: string, data: MediaAttachedData) {
        this.setupIsPlayingInterval(hls);
        this.playIfPaused(hls);
        this.sendEventToClient(hls, event, data);
    }

    private onMediaDetached(hls: Hls, event: string) {
        this.removeIsPlayingInterval();
        this.sendEventToClient(hls, event);
    }

    private sendEventToClient(
        hls: Hls,
        event: string,
        data?:
            LevelLoadedData |
            FragLoadEmergencyAbortedData |
            MediaAttachedData
    ) {
        this.eventBus.emit(Event.customAction, {
            hls: clientFacingHlsProvider(hls),
            data,
            event
        }, CONTEXT);
    }

    private playIfPaused(hls: Hls) {
        const isPaused = hls?.media?.paused;

        if (this.playerStateRepository.state.isPlaying && isPaused) {
            console.debug('[HlsProvider] hls play on media attached');
            hls?.media?.play();
        }
    }

    private onStateChange(state: Partial<PlayerState>, previousState?: PlayerState) {
        this.onPlayingStateChange(state, previousState);

        if (
            state.playback?.isStuck === false &&
            previousState?.playback?.isStuck === true
        ) {
            this.eventBus.emit(Event.customAction, {
                event: 'bufferingEnded'
            }, CONTEXT);
        }
    }

    private onPlayingStateChange(state: Partial<PlayerState>, previousState?: PlayerState) {
        const isPlaying = state.isPlaying;

        if (!this.hls || isPlaying === undefined) {
            return;
        }

        const wasPlaying = previousState?.isPlaying;
        const stoppedPlaying = wasPlaying && !isPlaying;
        const startedPlaying = !wasPlaying && isPlaying;

        if (startedPlaying) {
            console.debug('[HlsProvider] startLoad on play');
            this.hls.startLoad();
        } else if (stoppedPlaying) {
            const isLive = isLivePlaylist(this.hls);
            // In live streams we have the backBufferLength set to 0, and a liveMaxLatencyDurationCount < than Infinity.
            // If we don't stop the load, hls will keep loading segments and the paused frame will change frequently even if paused, which looks bad.
            // For VODs, we want to allow to fill a bit the buffer even if paused, so we don't stop the load
            // If isLive is undefined for some reason, we fallback to live behaviour because not having the frame change is more important than having a bit of buffer
            if (isLive || isLive === undefined) {
                console.debug('[HlsProvider] stopLoad on pause');
                this.hls.stopLoad();
            }
        }
    }

    private onHlsError(event: string, data: ErrorData) {
        if (!this.errorHandlers) {
            return console.error(`[HlsProvider] ${event} encountered, cannot recover as there are no handlers`, { event, data });
        }

        const details = data.details;
        const handler = get(this.errorHandlers, details) as HlsErrorHandler;

        if (isFunction(handler)) {
            return handler(event, data);
        }

        this.errorHandlers?.default(event, data);
    }

    private onChangeLevel(id: number) {
        console.log('[HlsProvider] select level', id);

        if (!this.hls) {
            return;
        }

        if (!isNumber(id) || id < -1 || id >= this.hls.levels.length) {
            return;
        }

        this.maxLevelFromUser = id;

        const maxLevel = resolveMaxLevel(this.maxLevelFromConfig, this.maxLevelFromUser);

        // autoLevelCapping doesn't seem to have any effect if the level is set to something else than -1 below
        this.hls.autoLevelCapping = maxLevel;
        this.hls.loadLevel = maxLevel;
        this.hls.currentLevel = maxLevel;
        this.hls.nextLevel = maxLevel;
    }

    // getters and setters

    private get isPlaying(): boolean {
        return this.playerStateRepository.state?.isPlaying;
    }

    private get stuck(): boolean {
        return !!this.playerStateRepository.state?.playback?.isStuck;
    }
}
