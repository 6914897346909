{
    "appearance": {
        "panels": {
            "tools": {
                "dock": "right"
            }
        },
        "theme": "light"
    },
    "customCSS": [
        ".blockbuilder-tools-panel { background-color: var(--base-100); };",
        ".blockbuilder-tools-panel > .nav-tabs-bg { background-color: var(--base-300) }",
        ".blockbuilder-tools-panel > .nav-tabs .nav-item .nav-link { background-color: var(--base-300); }",
        ".blockbuilder-tools-panel > .nav-tabs .nav-item .nav-link.active { background-color: var(--base-100); }",
        ".blockbuilder-tools-panel > .nav-tabs .nav-item .nav-link:hover { background-color: var(--base-100); }",
        ".blockbuilder-tools-panel .caret.caret-left::before { border-right-color: var(--base-300); }",
        ".blockbuilder-tools-panel .caret.caret-left::after { border-right-color: var(--base-100); }",
        ".nav-tabs { background-color: var(--base-100); color: var(--base-700); };",
        ".actions-container { border-radius: 4px; background-color: var(--base-100); border-color: var(--base-300); };",
        ".actions-container div { color: var(--base-700); };",
        ".actions-container a:hover { color: var(--base-600);};",
        ".actions-container .selected a { color: var(--primary-300);};",
        "#editor .loadedContent .u_body { min-height: calc(100vh - 55px) !important }"
    ],
    "features": {
        "ai": false,
        "audit": false,
        "stockImages": {
            "enabled": false
        },
        "userUploads": false
    },
    "projectId": 70008,
    "specialLinks": [
        {
            "href": "https://install.events/{{_app}}",
            "name": "Install link",
            "target": "_blank"
        },
        {
            "href": "{{_privacy_link}}",
            "name": "Privacy policy link",
            "target": "_blank"
        },
        {
            "name": "Calendar links",
            "specialLinks": [
                {
                    "href": "{{_calendar_ical_link}}",
                    "name": "Add to calendar",
                    "target": "_blank"
                },
                {
                    "href": "{{_calendar_google_link}}",
                    "name": "Add to Google Calendar",
                    "target": "_blank"
                }
            ]
        },
        {
            "name": "Event links",
            "specialLinks": [
                {
                    "href": "{{_webapp_link}}&redirect=/{{_eid}}/view/profile/{{_id}}",
                    "name": "User profile link",
                    "target": "_blank"
                },
                {
                    "href": "{{_webapp_link}}&redirect=/{{_eid}}/agenda",
                    "name": "Agenda link",
                    "target": "_blank"
                },
                {
                    "href": "{{_webapp_link}}&redirect=/{{_eid}}/speakers",
                    "name": "Speakers link",
                    "target": "_blank"
                },
                {
                    "href": "{{_webapp_link}}&redirect=/{{_eid}}/sponsors",
                    "name": "Sponsors link",
                    "target": "_blank"
                }
            ]
        },
        {
            "name": "Personalised links",
            "specialLinks": [
                {
                    "href": "{{_webapp_link}}",
                    "name": "Web App link",
                    "target": "_blank"
                },
                {
                    "href": "{{_registration_link}}",
                    "name": "Registration link",
                    "target": "_blank"
                },
                {
                    "href": "{{_registration_link}}&redirect=/registration",
                    "name": "Registration form link",
                    "target": "_blank"
                },
                {
                    "href": "{{unsubscribeUrl}}",
                    "name": "Unsubscribe link",
                    "target": "_blank"
                },
                {
                    "href": "{{_registration_link}}&redirect=/summary",
                    "name": "Sessions registration summary link",
                    "target": "_blank"
                }
            ]
        }
    ],
    "tabs": {
        "audit": {
            "active": false,
            "enabled": false,
            "position": 4
        },
        "blocks": {
            "active": false,
            "position": 2
        },
        "body": {
            "active": false,
            "position": 3
        },
        "contents": {
            "active": false,
            "position": 1
        },
        "uploads": {
            "enabled": false
        }
    },
    "tools": {
        "button": {
            "position": 3
        },
        "divider": {
            "position": 5
        },
        "heading": {
            "position": 1
        },
        "html": {
            "enabled": false
        },
        "image": {
            "position": 4
        },
        "menu": {
            "enabled": false
        },
        "text": {
            "position": 2
        }
    }
}
