// Utils
import { get, isFunction } from 'lodash';

export default {
    methods: {
        /**
         * Use "format" key of "cellConfig" to render the cell
         *
         * @param {GenericListColumnConfig} cellConfig
         * @param {Record<string, unknown>} item
         *
         * @returns {string|undefined} the formatted string, or undefined if could not be formatted
         */
        renderFormatted(cellConfig, item) {
            const format = cellConfig.format;
            if (isFunction(cellConfig.format)) {
                return cellConfig.format(item);
            }

            if (format === 'attachment') {
                const attachments = this.$utils.attachments.getAttachmentNames(item);
                return this.$utils.attachments.getAttachmentMetadata(item, attachments[0])?.content_type;
            }

            if (format === 'humanizeTimestamp') {
                let timestamp = get(item, cellConfig.field);
                if (typeof timestamp === 'string') {
                    timestamp = Number.parseInt(timestamp);
                }
                return timestamp
                    ? this.$utils.time.tsToFormat(timestamp, 'LLL', this.event.timezone)
                    : '';
            }

            if (get(item, format)) {
                return get(item, format);
            }

            console.warn('[mixins/formattable] Unknown cell formatter:', format);

            return undefined;
        }
    }
};
