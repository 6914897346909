// Utils
import { get } from 'lodash';

// Components
import SearchBar from 'shared/ui/components/form-components/SearchBar.vue';

export default {
    components: { SearchBar },

    data() {
        return {
            columns: [],
            fullCriteriaItems: undefined,
            fullItems: [],
            hasFullLocalData: true,
            items: [],
            searchTerm: '',
            showSearchBar: true
        };
    },

    watch: {
        searchTerm() {
            if (this.hasFullLocalData) {
                this.applyCriteriaLocally();
            } else {
                this.loadData();
            }
        }
    },

    methods: {
        /**
         * Applies search criteria locally.
         *
         * This method must be implemented in the extending component.
         *
         * @throws {Error} Throws an error if the method is not implemented in the extending component.
         */
        applyCriteriaLocally() {
            throw new Error('Method "applyCriteriaLocally" must be implemented in extending component.');
        },

        /**
         * Asynchronously loads data.
         *
         * This method must be implemented in the extending component.
         *
         * @throws {Error} Throws an error if the method is not implemented in the extending component.
         * @abstract
         */
        async loadData() {
            throw new Error('Method "loadData" must be implemented in extending component.');
        },

        /**
         * Applies the search criteria locally. If a search term is present,
         * it triggers the local search functionality.
         */
        applySearchCriteriaLocally() {
            const searched = !!this.searchTerm;
            if (searched) {
                this.localSearch();
            }
        },

        /**
         * Does a local search (used when all elements are here)
         */
        localSearch() {
            const items = [];
            for (const item of this.fullCriteriaItems ?? this.fullItems) {
                for (const column of this.columns) {
                    const value = get(item, column.field, '');
                    if (typeof value === 'string' &&
                        value.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase())) {
                        items.push(item);
                        break;
                    }
                }
            }
            this.fullCriteriaItems = items;
            this.total = items.length;
        },
    }
};
