export default {
    props: {
        attachment: {
            type: Object,
            required: true
        },

        event: {
            type: Object,
            required: true
        },

        name: {
            type: String,
            required: true
        },

        docId: {
            type: String,
            required: true
        },

        small: {
            type: Boolean,
            default: false
        },

        src: {
            type: String,
            default: null
        }
    },

    computed: {
        path() {
            return this.src || this.$utils.assets.getEventAttachmentPath(this.event._id, this.name, this.docId);
        }
    }
};
