export default {
    props: {
        theme: {
            type: Object,
            required: true
        },

        quickLinks: {
            type: Number,
            default: 5
        },

        banner: {
            type: String,
            default: null
        },

        pinnedPost: {
            type: String,
            default: null
        },

        showMobileMenu: {
            type: Boolean,
            default: true
        }
    },

    data() {
        const props = Object.keys(this.theme);
        props.push(...Object.keys(this.$services.theme.V3_THEMES['light']));

        const data = {
            bannerHeight: 0,
            pageTop: 63,
            createPostBoxHeight: 62,
            quickLinksHeight: 58,
            pinnedPostHeight: 75,
            margin: 8
        };
        for (const prop of props) {
            data[prop] = null;
        }

        return data;
    },

    computed: {
        isV3() {
            return ['light', 'dark'].includes(this.theme.appearance);
        },

        brandColor() {
            return this.isV3 ? this.color13 : this.color1;
        },

        createPostBoxTop() {
            return this.pageTop + this.bannerHeight + this.margin;
        },

        createPostBoxBottom() {
            return this.createPostBoxTop + this.createPostBoxHeight;
        },

        quickLinksTop() {
            if (this.quickLinks) {
                return this.createPostBoxBottom + this.margin;
            } else {
                return this.createPostBoxTop;
            }
        },

        quickLinksBottom() {
            if (this.quickLinks) {
                return this.quickLinksTop + this.quickLinksHeight;
            } else {
                return this.createPostBoxBottom;
            }
        },

        pinnedPostTop() {
            if (this.pinnedPost) {
                return this.quickLinksBottom + this.margin;
            } else {
                return this.quickLinksTop;
            }
        },

        pinnedPostBottom() {
            if (this.pinnedPost) {
                return this.pinnedPostTop + this.pinnedPostHeight;
            } else {
                return this.quickLinksBottom;
            }
        },

        otherPostsTop() {
            return this.pinnedPostBottom + this.margin;
        }
    },

    watch: {
        theme: {
            immediate: true,
            handler() {
                this.initColors();
            }
        },

        banner() {
            // This for when the banner changes
            // or gets removed.
            this.setBannerHeight();
        }
    },

    mounted() {
        // This for the component gets mounted
        // but the image has already been loaded by other means,
        // for example WebPreview component that loads the image
        // before MobilePreview component is mounted.
        this.setBannerHeight();
    },

    methods: {
        initColors() {
            for (const prop of Object.keys(this.theme)) {
                this[prop] = this.theme[prop];
            }

            const mapping = this.isV3 ? this.theme.appearance : 'light';
            const extraProps = this.$services.theme.V3_THEMES[mapping];
            for (const key of Object.keys(extraProps)) {
                this[key] = this.isV3 ? extraProps[key] : undefined;
            }
        },

        setBannerHeight() {
            if (!this.banner || !this.$refs.banner) {
                this.bannerHeight = 0;
                return;
            }

            setTimeout(() => this.computeBannerHeight(), 250);
        },

        computeBannerHeight() {
            requestAnimationFrame(() => {
                const { height } = this.$refs.banner.getBBox();
                this.bannerHeight = height;
            });
        },

        onBannerLoad() {
            // This for when the banner image is loaded.
            this.setBannerHeight();
        }
    }
};
