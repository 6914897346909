import Hls from 'hls.js';

export function isLivePlaylist(hls: Hls): boolean | undefined {
    const currLevelIndex = hls?.currentLevel ?? 0;
    const currLevel = hls?.levels?.[currLevelIndex];
    return currLevel?.details?.live;
}

export function isIos18OrHigherDevice(): boolean {
    try {
        if (!(/iPad|iPhone|iPod/.test(navigator.userAgent))) {
            return false;
        }
        const match = navigator.userAgent.match(/OS (\d+)_/);
        return !!match && parseInt(match[1], 10) >= 18;
    } catch (e) {
        return false;
    }
}
