import { isValidFieldName } from 'libs/utils/metadata';

export function register(extend, i18n) {
    extend('metadata_field_name', {
        message: field => i18n.t('validations.invalid_field_name', [field]).toString(),
        validate: value => isValidFieldName(value)
    });

    extend('unique_field_name', {
        message: field => i18n.t('validations.name_exists', [field]).toString(),
        params: ['names'],
        validate: (value, { names }) => {
            if (!value) return true;
            const allNames = names();
            if (!allNames.includes(value)) return true;
            const occurrences = allNames.filter(n => n === value)?.length;

            return occurrences === 1;
        }
    });
}
