// Utils
import { cloneDeep, isEmpty, isEqual, get } from 'lodash';
import { generateGetters, generateSetters } from 'libs/utils/store';
import { EventEmitter } from 'libs/utils/event-emitter';
import { STREAM_STATES } from 'libs/services/live-stream';
import { addEventListener } from 'libs/utils/helpers/dom';

const EVENT = {
    error: 'error',
    update: 'update',
    beforeUpdate: 'beforeUpdate'
};
import { WORDLY_URL } from 'libs/services/live-stream';
import { isNewDocNewer } from 'libs/utils/helpers/docs';
import { isCookieStillValid, setupExpirationTimeout } from 'libs/utils/cookies';

const STUDIO_LOG_NAME = 'studio.live-stream-store';

const UPDATE_MAX_INTERVAL = 60 * 1000;
const ACCEPTABLE_SERVER_TIME_DIFF = 3000 ; // 3 seconds
const FRAME_RATE_INTERVAL = 10 * 1000;

// we don't ever need these to be reactive
let emitter;
let unsubscribers = [];

const smallWindowMediaQuery = window.matchMedia('(max-width: 1200px)');

const initialState = {
    eventId: null,
    liveStreamId: null,
    liveStream: null,
    liveStreamUrl: '',
    msDelta: 0,
    credentials: null,
    frameRate: 0,
    useCredentials: false,
    videoOutputReady: false,
    requestedTabView: false,
    tabView: smallWindowMediaQuery.matches
};

let frameRateTimeout;
let liveStreamUpdateTimeout;
let urlExpireTimestamp = {};
let expirationTimeoutId;

/**
 * @param {()=>Services} $services
 * @param {import('libs/utils/constants')} $const
 * @param {$utils} $utils
 * @returns {import("vuex").Module}
 */
// eslint-disable-next-line no-unused-vars
export default ($services, $const, $utils) => ({
    namespaced: true,
    state: {
        ...cloneDeep(initialState)
    },
    mutations: {
        ...generateSetters(initialState),
        cleanLiveStream(state) {
            state.liveStream = null;
        },
        // we do this separately because it's only required in specific cases
        cleanLiveStreamId(state) {
            state.liveStreamId = null;
        },
        setRequestedTabView(state, value) {
            state.requestedTabView = value;
            state.tabView = value;
        }
    },
    actions: {
        /**
         * @param {import("vuex").ActionContext} context
         * @param {Object} params
         * @param {String} params.eventId
         * @param {String} params.liveStreamId
         * @param {Object} params.eventEmitter
         */
        async init({ dispatch, commit, state }, { eventId, liveStreamId, eventEmitter = new EventEmitter() }) {
            if (state.liveStreamId === liveStreamId && state.eventId === eventId) {
                return;
            }

            dispatch('_dispose');

            commit('setEventId', eventId);
            commit('setLiveStreamId', liveStreamId);
            emitter = eventEmitter;

            const signalStateUpdate = $const.LIVE_STREAM_STATE_UPDATE.replace('{{liveStreamId}}', liveStreamId);

            const studioUserId = $services().studio.base.getUserId();
            if (studioUserId) {
                $services().studio.logging.initLogger({
                    eventId,
                    logName: STUDIO_LOG_NAME,
                    liveStreamId: liveStreamId,
                    userId: studioUserId
                });
            }

            unsubscribers.push(
                await $services().signal.addSignalListener(signalStateUpdate, ({ liveStream }) => dispatch('updateLiveStream', liveStream)),
                $services().signal.addEventListener('open', () => dispatch('updateLiveStream')),
                addEventListener(smallWindowMediaQuery, 'change', (event) => commit('setTabView', state.requestedTabView || event.matches))
            );
        },
        /**
         * never call this from some components' destroyed hook, another component might have called init moments before
         * @param {import("vuex").ActionContext} context
         */
        _dispose: ({ commit }) => {
            clearTimeout(frameRateTimeout);
            frameRateTimeout = null;
            clearTimeout(expirationTimeoutId);
            clearTimeout(liveStreamUpdateTimeout);
            commit('cleanLiveStream');
            if (emitter) {
                emitter.unsubscribe(unsubscribers);
            }
            unsubscribers = [];
        },

        /**
         * @param {import("vuex").ActionContext} ctx
         * @param {function} handler
         */
        onBeforeUpdate(ctx, handler) {
            return emitter.addEventListener(EVENT.beforeUpdate, handler);
        },

        /**
         * @param {import("vuex").ActionContext} ctx
         * @param {function} handler
         */
        onUpdate(ctx, handler) {
            return emitter.addEventListener(EVENT.update, handler);
        },

        /**
         * @param {import("vuex").ActionContext} ctx
         * @param {function} handler
         */
        onError(ctx, handler) {
            return emitter.addEventListener(EVENT.error, handler);
        },
        /**
         * @param {import("vuex").ActionContext} context
         * @param {Object} [liveStream]
         */
        async updateLiveStream({ state, dispatch }, liveStream) {
            clearTimeout(liveStreamUpdateTimeout);

            const oldLiveStream = cloneDeep(state.liveStream);

            try {
                await dispatch('fetchLiveStream', liveStream);
                emitter.emit(EVENT.beforeUpdate, oldLiveStream);
            } catch (error) {
                emitter.emit(EVENT.error, error);
                return;
            }

            if (!isEqual(state.liveStream, oldLiveStream)) {
                emitter.emit(EVENT.update, state.liveStream);
            }

            const pipelineState = $services().liveStream.getState(liveStream);
            const isFinished = $services().liveStream.isFinished(liveStream);

            const periodicUpdateOnStates = [
                STREAM_STATES.IDLE,
                STREAM_STATES.RESERVING,
                STREAM_STATES.STARTING,
                STREAM_STATES.RUNNING
            ];
            if (periodicUpdateOnStates.includes(pipelineState) && !isFinished) {
                liveStreamUpdateTimeout = setTimeout(() => {
                    dispatch('updateLiveStream');
                }, UPDATE_MAX_INTERVAL);
            }
        },
        /**
         * @param {import("vuex").ActionContext} context
         */
        async fetchIfNeeded({ state, dispatch }) {
            if (!state.liveStream) {
                await dispatch('fetchLiveStream');
            }
        },
        /**
         * @param {import("vuex").ActionContext} context
         * @param {Object} [cachedLiveStream]
         */
        async fetchLiveStream({ state, commit, dispatch, getters, rootGetters }, cachedLiveStream) {
            const liveStream = cachedLiveStream || await $services().liveStream.getDoc(state.eventId, state.liveStreamId);

            const doesNewOneHaveHigherVersion = isNewDocNewer(liveStream, state.liveStream);
            if (state.liveStream && !doesNewOneHaveHigherVersion) {
                return;
            }

            if ($services().studio) {
                const backgroundImageUrl = $services().studio.base.getBackgroundImageUrl(state.eventId, liveStream);

                // we normally keep 2 instances, one for the picker, one for the stream,
                // so the stream one is only updated on save
                // TODO: validate if this is still needed now that we have liveStreamSettings module
                liveStream.background = backgroundImageUrl ? [backgroundImageUrl] : null;
                liveStream.backgroundImageUrl = backgroundImageUrl || null;
            }

            const isHost = rootGetters['liveSession/speakerSettings/hasHostPermissions'];
            if (!$services().liveStream.isThirdParty(liveStream) && isHost) {
                const credentialsDoc = await $services().liveStream.getCredentials(state.eventId, state.liveStreamId);

                if (credentialsDoc) {
                    dispatch('transformCredentials', credentialsDoc);

                    if (!isEmpty(credentialsDoc.inputs) && isEmpty(liveStream.fp_system.inputs)) {
                        liveStream.fp_system.inputs = credentialsDoc.inputs;
                    }
                }
            }

            if (getters.isStudio && $services().liveStream.isFinished(liveStream) && !$services().liveStream.getIsFinishedTimeStamp(liveStream)) {
                this.$services.studio.logging.log(STUDIO_LOG_NAME, 'error', 'Live stream doc is finished but does not have finished timestamp. This should not happen.');
            }

            commit('setLiveStream', liveStream);
            dispatch('resolveLiveStreamUrl');

            if (liveStream.current_time) {
                const delta = Date.now() - liveStream.current_time;

                if (Math.abs(delta) > ACCEPTABLE_SERVER_TIME_DIFF) {
                    commit('setMsDelta', delta);
                }
            }

            if (getters.isRtmps || getters.isStudio) {
                dispatch('startFrameRateTimeout');
            }
        },

        transformCredentials: ({ commit }, doc) => {
            const inputs = get(doc, 'inputs.0', {});
            const directInputs = get(doc, 'directInputs.0', '');
            const studioOutput = get(doc, 'studio_output', {});
            const multicast = get(doc, 'multicast', {});

            const credentials = {
                liveStreamRtmpsUrl: inputs.pushUrl || '',
                liveStreamRtmpsKey: inputs.streamKey || '',
                liveStreamRtmpUrl: directInputs.substring(0, directInputs.lastIndexOf('/')),
                liveStreamRtmpKey: directInputs.substring(directInputs.lastIndexOf('/') + 1),
                studioRtmpsUrl: studioOutput.pushUrl || inputs.pushUrl,
                studioRtmpsKey: studioOutput.streamKey || inputs.streamKey,
                multicastUrl: multicast.url || WORDLY_URL,
                multicastKey: multicast.key || ''
            };

            commit('setCredentials', credentials);
        },

        async startFrameRateTimeout({ commit, dispatch, state, getters, rootGetters }) {
            if (frameRateTimeout) {
                return;
            }

            const isHost = rootGetters['liveSession/speakerSettings/hasHostPermissions'];
            const isModerator = rootGetters['liveSession/speakerSettings/isModerator'];
            if (!isModerator && !isHost) {
                return;
            }

            if (!getters.isRunning || getters.isFinished) {
                commit('setFrameRate', 0);
                commit('setVideoOutputReady', false);
                return;
            }

            // In studio we just need proof that the compositor started streaming to go live, then the framerate is useless
            if (getters.isStudio && (state.videoOutputReady || getters.isLive)) {
                commit('setVideoOutputReady', true);
                return;
            }

            frameRateTimeout = setTimeout(() => {
                frameRateTimeout = null;
                dispatch('startFrameRateTimeout');
            }, FRAME_RATE_INTERVAL);

            const inputFrameRate = await $services().liveStream
                .getFrameRate(state.eventId, state.liveStreamId)
                .catch(error => {
                    console.error('[LiveStreamStore] failed to contact server', error);
                });

            const fps = Math.round((inputFrameRate || 0) * 10) / 10;

            commit('setFrameRate', fps);

            if (fps && fps > 0) {
                console.log('[LiveStreamStore] video output seems ready because fps is', fps);
                commit('setVideoOutputReady', true);
            }
        },

        async resolveLiveStreamUrl({ state, getters, commit, dispatch }) {
            const liveStream = state.liveStream;
            const liveStreamUrl = $services().liveStream.getStreamUrl(liveStream);

            if (!liveStreamUrl || getters.isThirdParty) {
                return commit('setLiveStreamUrl', liveStreamUrl);
            }

            if (!getters.isRunning && !getters.vodComplete) {
                return commit('setLiveStreamUrl', '');
            }

            console.debug('[LiveStreamStore] Getting signed url');

            if (isCookieStillValid(urlExpireTimestamp[liveStreamUrl])) {
                return;
            }
            urlExpireTimestamp = {};

            try {
                const { url, cookies, expires } = await $services().liveStream.getSignedUrl(state.eventId, state.liveStreamId, state.liveStream?.version);

                if (get(window, 'BSTG.env') !== 'dev') {
                    commit('setUseCredentials', !!cookies);
                }

                commit('setLiveStreamUrl', url);

                if (expires) {
                    urlExpireTimestamp = { [liveStreamUrl]: expires };
                    clearTimeout(expirationTimeoutId);
                    expirationTimeoutId = setupExpirationTimeout(expires, () => dispatch('resolveLiveStreamUrl'));
                }
            } catch (error) {
                console.error('[LiveStreamStore] Couldn\'t get signed url', error.message);
                commit('setLiveStreamUrl', liveStreamUrl);
            }
        }
    },
    getters: {
        ...generateGetters(initialState),

        liveStreamId(state) {
            return get(state, 'liveStream._id');
        },

        isError(state) {
            return $services().liveStream.isError(state.liveStream);
        },

        isRtmps(state) {
            return $services().liveStream.isRtmps(state.liveStream);
        },

        isStudio(state) {
            return $services().liveStream.isStudio(state.liveStream);
        },

        isThirdParty(state) {
            return $services().liveStream.isThirdParty(state.liveStream);
        },

        isFinished(state) {
            return $services().liveStream.isFinished(state.liveStream);
        },

        isRunning(state) {
            return !!$services().liveStream.isRunning(state.liveStream);
        },

        isPreview(state) {
            return $services().liveStream.isPreview(state.liveStream);
        },

        isLive(state) {
            return $services().liveStream.isLive(state.liveStream);
        },

        isLiveTimestamp(state) {
            return $services().liveStream.getIsLiveTimeStamp(state.liveStream);
        },

        isFinishedTimestamp(state) {
            return $services().liveStream.getIsFinishedTimeStamp(state.liveStream);
        },

        startTime(state) {
            return $services().liveStream.startTime(state.liveStream);
        },

        /**
         * @deprecated unclear naming, use vodGenerationStarted instead
         */
        isPublished(state) {
            return $services().liveStream.isPublished(state.liveStream);
        },

        vodGenerationStarted(state) {
            return $services().liveStream.vodGenerationStarted(state.liveStream);
        },

        vodWasGenerated(state) {
            return $services().liveStream.vodWasGenerated(state.liveStream);
        },

        isStopping(state) {
            return $services().liveStream.isStopping(state.liveStream);
        },

        isScheduled(state) {
            return $services().liveStream.isScheduled(state.liveStream);
        },

        isReleased(state) {
            return $services().liveStream.isReleased(state.liveStream);
        },

        pipelineLoading(state) {
            return $services().liveStream.pipelineLoading(state.liveStream);
        },

        isReserving(state) {
            return $services().liveStream.isReserving(state.liveStream);
        },

        isBootstrapping(state) {
            return $services().liveStream.isBootstrapping(state.liveStream);
        },

        isResetting(state) {
            return $services().liveStream.isResetting(state.liveStream);
        },

        vodComplete(state) {
            return $services().liveStream.vodComplete(state.liveStream);
        },

        isGeneratingReplay(state) {
            return $services().liveStream.isGeneratingReplay(state.liveStream);
        },

        isHybrid(state) {
            return $services().liveStream.isHybrid(state.liveStream);
        },

        isInPerson(state) {
            return $services().liveStream.isInPerson(state.liveStream);
        },

        isVirtual(state) {
            return $services().liveStream.isVirtual(state.liveStream);
        },

        sessionType(state) {
            return $services().liveStream.getSessionType(state.liveStream);
        },

        url(state) {
            return $services().liveStream.getStreamUrl(state.liveStream);
        },

        liveCaptionsLabels(state) {
            return $services().liveStream.getLiveCaptionsLabels(state.liveStream);
        },

        rtmpsInputs(state) {
            return $services().liveStream.getRtmpsInputs(state.liveStream);
        },

        configOverwrites(state) {
            return state?.liveStream?.config_overwrites ?? {};
        },

        playerConfigOverwrites(state, getters) {
            return getters?.configOverwrites?.player ?? {};
        },

        hlsConfigOverwrites(state, getters) {
            return getters?.configOverwrites?.hls ?? {};
        },

        autostopEmptyDurationMinutes(state) {
            return state?.liveStream?.autostop_empty_duration_minutes;
        },

        liveStreamBackgroundUrl(state) {
            return state?.liveStream?.backgroundImageUrl;
        },

        isVirtualBackgroundsEnabled(state) {
            return state?.liveStream?.virtual_backgrounds_enabled;
        }
    }
});
