// Utils
import { get } from 'lodash';

// Components
import ListFilters from 'shared/ui/components/listings/ListFilters.vue';

export default {
    components: { ListFilters },

    data() {
        return {
            /** @type { {[fieldName: string]: matchValue[]} } */
            activeFilters: {},
            fullCriteriaItems: undefined,
            fullItems: [],
            hasFullLocalData: true,
            total: 0
        };
    },

    watch: {
        activeFilters() {
            if (this.hasFullLocalData) {
                this.applyCriteriaLocally();
            } else {
                this.loadData();
            }
        }
    },

    methods: {
        /**
         * Applies search criteria locally.
         *
         * This method must be implemented in the extending component.
         *
         * @throws {Error} Throws an error if the method is not implemented in the extending component.
         */
        applyCriteriaLocally() {
            throw new Error('Method "applyCriteriaLocally" must be implemented in extending component.');
        },

        /**
         * Asynchronously loads data.
         *
         * This method must be implemented in the extending component.
         *
         * @throws {Error} Throws an error if the method is not implemented in the extending component.
         * @abstract
         */
        async loadData() {
            throw new Error('Method "loadData" must be implemented in extending component.');
        },

        /**
         * Applies filter criteria locally to the data.
         *
         * This method checks if there are any active filters that are arrays with at least one element.
         * If such filters exist, it calls the `localFilter` method to apply the filters locally.
         */
        applyFilterCriteriaLocally() {
            const filtered = Object.values(this.activeFilters).some(
                filter => Array.isArray(filter) && filter.length
            );

            if (filtered) {
                this.localFilter();
            }
        },

        /**
         * Does a local filter (used when all elements are here)
         */
        localFilter() {
            const items = (this.fullCriteriaItems ?? this.fullItems).filter(item => {
                for (const field of Object.keys(this.activeFilters)) {
                    const value = get(item, field, '');
                    const rules = this.activeFilters[field];
                    const currentFieldMatchesFilter = !rules.filter(Boolean).length || rules.includes(value);

                    if (!currentFieldMatchesFilter) {
                        return false;
                    }
                }

                return true;
            });

            this.fullCriteriaItems = items;
            this.total = items.length;
        },
    }
};
