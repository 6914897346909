// Utils
import moment from 'moment-timezone';
import { get } from 'lodash';

export default {
    props: {
        event: {
            type: Object,
            required: true
        },

        user: {
            type: Object,
            required: true
        },

        saveSpy: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            ready: false,
            innerValue: {}
        };
    },

    computed: {
        /** @returns {boolean} */
        userIsSuperAdmin() {
            return this.$services.user.isSuperAdmin();
        },

        /** @returns {boolean} */
        userIsAdminOrSupport() {
            return this.$services.user.matchesRoles(
                { globalRole: 'superadmin' },
                { globalRole: 'spotme_support' }
            );
        },

        /** @returns {boolean} */
        userIsAdminOfOrg() {
            return this.$services.user.matchesRoles({
                orgRole: 'admin',
                orgId: this.event.ownerId
            });
        },


        canChangeStatusFromTestToPreprod() {
            return this.$services.user.hasPermission(
                'global_actions.create_production_event',
                { org_id: this.event.ownerId },
            );
        },

        /** @returns {boolean} */
        canChangeSettings() {
            return this.$services.user.hasPermission('node_crud_realms.modify_event_doc', { event_id: this.event._id });
        },

        /** @returns {boolean} */
        canChangeDate() {
            return this.$services.user.hasPermission('node_crud_realms.modify_event_dates', { event_id: this.event._id });
        },

        /** @returns {boolean} */
        canChangeStartDate() {
            const eventStartTs = get(this.event, ['description', 'start_time']);
            const endOfTodayTs = moment().endOf('day').unix();

            return this.canChangeDate || !eventStartTs || eventStartTs > endOfTodayTs;
        },

        /** @returns {boolean} */
        canAccessOrgData() {
            return this.$services.user.hasPermission('org_realms.read_org_doc', { org_id: this.event.ownerId });
        },

        /** @returns {boolean} */
        canChangeEventOwner() {
            return this.$services.user.matchesRoles(
                { globalRole: 'superadmin' },
                { globalRole: 'spotme' }
            );
        }
    },

    watch: {
        event: {
            immediate: true,
            handler() {
                this.$nextTick(async () => {
                    await this.init();
                });
            }
        },

        async saveSpy() {
            await this.emit();
        },
    },

    methods: {
        init() {
            console.warn('[settings] This method must be implemented in the receiving component');
        },

        async emit() {
            if (typeof this.preSubmitHook === 'function') {
                await this.preSubmitHook();
            }

            const valid = await this.$refs.form.validate();

            if (valid) {
                this.$emit('save', this.innerValue);
            }
        },

        isFormDirty() {
            return get(this.$refs, 'form.flags.dirty', false);
        },

        resetFormValidation() {
            return this.$refs.form.reset();
        },

        redirectToTemplate() {
            const path = this.$utils.url.interpolateUrl(this.$const.CREATE_TEMPLATE_FROM_EVENT_ROUTE, { eventId: this.event._id });
            const url = this.$services.router.getHomeUrl(path);

            console.info('[settings] Redirecting to', url);
            this.$services.router.redirect(url);
        },

        /** @returns {boolean} */
        isUserPartOfOrg(orgId) {
            return this.user.orgs?.some(org => org._id === orgId);
        },
    }
};
