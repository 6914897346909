// Plugins
import VModal from 'vue-js-modal';
import VTooltip from 'v-tooltip';
import VueLazyload from 'vue-lazyload';
import VueCarousel from 'vue-carousel';
import VueCookies from 'vue-cookies';
import * as VeeValidate from 'vee-validate';
import Multiselect from 'vue-multiselect';
import Notifications from 'vue-notification';
import VueClipboard from 'vue-clipboard2';
import PortalVue from 'portal-vue';

// Our custom plugins
import Alert from 'shared/ui/plugins/alert';
import Tracker from 'ui/plugins/tracker';
import ModalsExt from 'shared/ui/plugins/modals-ext';

// Directives
import Browsers from 'ui/utils/directives/browsers';
import CSSSupport from 'ui/utils/directives/css-supports';

// Global components
import Loader from 'shared/ui/components/Loader.vue';
import MetaControl from 'shared/ui/components/form-components/MetaControl.vue';
import ProgressBar from 'shared/ui/components/ProgressBar.vue';
import ProgressRing from 'shared/ui/components/ProgressRing.vue';
import RouterLinkNg from 'shared/ui/components/RouterLinkNg';
import SecondaryToolbar from 'shared/ui/components/form-components/SecondaryToolbar.vue';
import CreateWorkspace from 'ui/components/wizards/CreateWorkspace.vue';

// Custom controls
import AttendeePicker from 'ui/components/meetings/AttendeePicker.vue';
import CheckInStatus from 'ui/components/agenda/CheckInStatus.vue';
import LocationTimeSlots from 'ui/components/meetings/locations/LocationTimeSlots.vue';
import MeetingLocationPicker from 'ui/components/meetings/MeetingLocationPicker.vue';
import MeetingRequestStatusSection from 'ui/components/meetings/meeting-requests/StatusSection.vue';
import MeetingRequestTimeslotPicker from 'ui/components/meetings/meeting-requests/TimeslotPicker.vue';
import PaxRequestActions from 'shared/ui/components/registrations/PaxRequestActions.vue';
import QrScansCount from 'ui/components/agenda/QrScansCount.vue';
import SessionRegistrations from 'ui/components/agenda/SessionRegistrations.vue';
import SessionSlots from 'ui/components/agenda/SessionSlots.vue';
import SpeaksAt from 'ui/components/presenters/SpeaksAt.vue';

// Custom actions components
import CopyPermalinkButton from 'shared/ui/components/listings/custom-actions/CopyPermalinkButton.vue';
import LeaderboardExportButton from 'shared/ui/components/listings/custom-actions/LeaderboardExportButton.vue';
import LeaderboardUpdateButton from 'shared/ui/components/listings/custom-actions/LeaderboardUpdateButton.vue';
import OpenModalAction from 'shared/ui/components/listings/custom-actions/OpenModalAction.vue';

// Custom cells components
import AssetPreview from 'shared/ui/components/uploader/AssetPreview.vue';
import BadgeCell from 'shared/ui/components/listings/custom-cell/BadgeCell.vue';
import ScheduledJobsDeleteCell from 'shared/ui/components/listings/custom-cell/ScheduledJobsDeleteCell.vue';
import ScheduledJobsNodeCell from 'shared/ui/components/listings/custom-cell/ScheduledJobsNodeCell.vue';
import ScheduledJobsRefreshCell from 'shared/ui/components/listings/custom-cell/ScheduledJobsRefreshCell.vue';
import SendNotificationCell from 'shared/ui/components/listings/custom-cell/SendNotificationCell.vue';
import SessionRegistrationCell from 'shared/ui/components/listings/custom-cell/SessionRegistrationCell.vue';
import StringListCell from 'shared/ui/components/listings/custom-cell/StringListCell.vue';

const GLOBAL_COMPONENTS = {
    'loader': Loader,
    'progress-bar': ProgressBar,
    'progress-ring': ProgressRing,
    'create-workspace': CreateWorkspace,
    'meta-control': MetaControl,
    'multiselect': Multiselect,
    'secondary-toolbar': SecondaryToolbar,
    'validation-provider': VeeValidate.ValidationProvider,
    'validation-observer': VeeValidate.ValidationObserver,
    'router-link': RouterLinkNg,
};

export default {
    /** @param {import('vue').VueConstructor} app */
    install: (app) => {
        Object.assign(app.prototype.$const.CUSTOM_KIND_COMPONENTS, {
            AttendeePicker,
            CheckInStatus,
            LocationTimeSlots,
            MeetingLocationPicker,
            MeetingRequestStatusSection,
            MeetingRequestTimeslotPicker,
            PaxRequestActions,
            QrScansCount,
            SessionRegistrations,
            SessionSlots,
            SpeaksAt
        });

        Object.assign(app.prototype.$const.CUSTOM_ACTIONS_COMPONENTS, {
            CopyPermalinkButton,
            LeaderboardExportButton,
            LeaderboardUpdateButton,
            OpenModalAction
        });

        Object.assign(app.prototype.$const.CUSTOM_CELLS_COMPONENTS, {
            AssetPreview,
            BadgeCell,
            ScheduledJobsDeleteCell,
            ScheduledJobsNodeCell,
            ScheduledJobsRefreshCell,
            SendNotificationCell,
            SessionRegistrationCell,
            StringListCell
        });

        app.use(VueCookies, { expires: app.prototype.$const.DEFAULT_COOKIES_EXPIRATION_PERIOD });
        app.use(VModal, { dynamic: true, injectModalsContainer: true, dialog: true });
        app.use(VTooltip, { defaultContainer: 'body', defaultHtml: false });
        app.use(Notifications);
        app.use(VueLazyload);
        app.use(VueCarousel);
        app.use(VueClipboard);
        app.use(Alert);
        app.use(Tracker);
        app.use(ModalsExt);
        app.use(PortalVue);

        app.directive('browser', Browsers);
        app.directive('css-support', CSSSupport);

        // Global registration of components
        for (const componentName of Object.keys(GLOBAL_COMPONENTS)) {
            app.component(componentName, GLOBAL_COMPONENTS[componentName]);
        }
    }
};
